import React, { useEffect, useState } from "react";
import axios from "axios";
import "./FeedBackForm.scss";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";

const FeedBackForm = ({ title }) => {
  const [email, setEmail] = useState("");
  const [web, setWeb] = useState("");
  const [comment, setComment] = useState("");
  const [userName, setUserName] = useState("");
  const [commentArr, setCommentArr] = useState<any[]>([])
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [commentCheck, setCommentCheck] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleWebsite = (e) => {
    setWeb(e.target.value);
  };
  const handleComment = (e) => {
    setComment(e.target.value);
  };
  const handleUserName = (e) => {
    setUserName(e.target.value);
  };

  useEffect(() => {
    fetch('https://strapi.phonera.in/api/blog-comments', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json())
      .then((data) => {
        setCommentArr(data.data)
        setUrl(window.location.pathname)
      })
  })

  if (commentArr.length !== 0) {
    commentArr.map((e, i) => {
      const { attributes } = e
      const { approved, blogUrl } = attributes
      if (approved === true && blogUrl === url) {
        setCommentCheck(true)
      }
    })
  }

  const hideResponse = () => {
    if (document !== undefined) {
      (document.getElementsByClassName('resp-msg') as HTMLCollectionOf<HTMLElement>)[0].style.display = "none";
    }
  }

  const UserDetails = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (window !== undefined && document !== undefined) {
      await axios
        .post("https://strapi.phonera.in/api/blog-comments", {
          data: {
            name: userName,
            email: email,
            comment: comment,
            website: web,
            approved: false,
            blogUrl: window.location.pathname
          },
        })
        .then(function (response) {
          setEmail('')
          setUserName('')
          setWeb('')
          setComment('')
          setIsLoading(false);
          (document.getElementsByClassName('resp-msg') as HTMLCollectionOf<HTMLElement>)[0].style.display = "block";
          setTimeout(hideResponse, 5000);
        })
        .catch(function (error) {
          console.log(error.message)
          setIsLoading(false);
        });
    }
  };
  return (
    <><div className="leaveMessage">
      <h5>LEAVE A REPLY</h5>
      <p className="py-4">
        Your email address will not be published. Required fields are marked{" "}
        <sup>*</sup>
      </p>
      <form action="">
        <div className="row gy-5 mb-5">
          <div className="col-lg-12">
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label"
              >
                Comment<sup>*</sup>
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={5}
                onChange={handleComment}
              ></textarea>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="commentName" className="form-label">
                Name<sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                id="commentName"
                required
                value={userName}
                onChange={handleUserName} />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="commentEmail" className="form-label">
                Email<sup>*</sup>
              </label>
              <input
                type="email"
                className="form-control"
                id="commentEmail"
                required
                value={email}
                onChange={handleChange} />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="commentWebsite" className="form-label">
                Website
              </label>
              <input
                type="text"
                className="form-control"
                id="commentWebsite"
                onChange={handleWebsite}
                value={web} />
            </div>
          </div>

          <div className="col-lg-9 col-md-8">
            <div className="form-check">
              <input
                className="form-check-input"
                id="flexCheckDefault"
                type="checkbox" />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Save my name, email, and website in this browser for the next time I comment.
              </label>
            </div>
            <h6 className="resp-msg">Response has been Submited, It will be Posted As Soon as it is Approved!!!</h6>
            {isLoading ? <LoadingSpinner /> : null}
          </div>
          <div className="col-sm-4 col-xl-3 col-5">
            <button
              className="btnpostcomment"
              type="submit"
              onClick={UserDetails}
            >
              POST COMMENT
            </button>
          </div>
        </div>
      </form>
    </div>
      {commentCheck ?
        <div className="container comments-section pb-5">
          <h5 className="mb-4">Comments On "{title}"</h5>
          {commentArr.map((e) => {
            const { id, attributes } = e
            const { name, comment, approved, blogUrl } = attributes
            // const date = new Date(createdAt).getDate();
            // const month = new Date(createdAt).toLocaleString('en-us', { month: 'long' });
            // const year = new Date(createdAt).getFullYear();
            // const time = new Date(createdAt).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });

            // const timeOfComment = `${month} ${date}, ${year}, ${time} `

            return (
              <>
                {blogUrl === url && approved ?
                  <div className="py-3" key={id}>
                    <div className="pt-3">
                      <div className="d-flex comment">
                        <img className="rounded-circle comment-img" src={`https://via.placeholder.com/128/423d3d/ffffff.png?text=${name.charAt(0)}`} />
                        <div className="ms-3">
                          <div className="mb-1">
                            <span className="fw-bold me-1">{name}</span>
                            <div className="mb-2">
                              {comment}
                            </div>
                          </div>

                        </div>
                        {/* <div className="ms-3">
                        <div className="mb-1">
                          <span className="text-muted text-nowrap">{timeOfComment}</span></div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  : null}
              </>
            )
          })}
        </div>
        : null}
    </>
  );
};

export default React.memo(FeedBackForm);