import React from "react";
import { RiArrowRightSLine } from "@react-icons/all-files/ri/RiArrowRightSLine";
import { RiArrowLeftSLine } from "@react-icons/all-files/ri/RiArrowLeftSLine";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";

const NextPreviousArticle = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiPost {
        edges {
          next {
            slug
            Title
            date
            admin_users {
              username
            }
            adminImg {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 40)
                }
              }
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, height: 150)
                }
              }
            }
          }
          node {
            slug
            Title
          }
          previous {
            slug
            Title
            date
            admin_users {
              username
            }
            adminImg {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 40)
                }
              }
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, height: 150)
                }
              }
            }
          }
        }
      }
    }
  `);

  const edgesData = data.allStrapiPost.edges;

  if (typeof window !== "undefined") {
    const path = window.location.pathname;
    let obj = edgesData.find((e) => path.includes(e.node.slug));

    if (obj?.next != null && obj?.previous != null) {
      const next = obj.next;
      const previous = obj.previous;

      return (
        <div className="prev-next">
          <div className="row">
            <React.Fragment key={""}>
              <div className="col-lg-6 prev-border">
                <div className="prevBox">
                  <div className="d-flex mb-4">
                    <Link to={`/${previous.slug}/`}>
                      <div className="arr-left">
                        <RiArrowLeftSLine />
                      </div>
                    </Link>
                    <span>PREVIOUS ARTICLE</span>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-2 col-4">
                      <Link to={`/${previous.slug}/`} >
                        <div className="slide-image">
                          <GatsbyImage
                            image={
                              previous.image.localFile?.childImageSharp
                                ?.gatsbyImageData
                            }
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            alt="reviews-content"
                            className="img-fluid"
                            loading="eager"
                            objectFit="none"
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-8 col-sm-10 col-8">
                      <Link to={`/${previous.slug}/`}>
                        <h5
                          className="next-heading"
                          dangerouslySetInnerHTML={{
                            __html: previous.Title,
                          }}
                        ></h5>
                      </Link>
                      <div className="prevAdminUser d-flex align-items-center">
                        {/* <div className="adminImg">
                          <GatsbyImage
                            image={
                              previous.adminImg.localFile?.childImageSharp
                                ?.gatsbyImageData
                            }
                            alt="costpixel"
                            loading="eager"
                            objectFit="cover"
                          />
                        </div> */}
                        {/* <div className="adminBy">
                          <span>by</span>
                          <span>
                            <Link
                              to={`/${previous.admin_users[0].username?.replace(
                                " ",
                                "-"
                              )}/`}
                            >
                              {previous.admin_users[0].username}
                            </Link>
                          </span>
                        </div> */}
                        <div className="august">
                          <span>{previous.date}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pt-lg-0 pt-4 d-lg-flex d-xs-flex">
                <div className="nextBox">
                  <div className="justify-content-end d-flex align-items-center mb-4">
                    <span>NEXT ARTICLE</span>
                    <Link to={`/${next.slug}/`}>
                      <div className="arr-right">
                        <RiArrowRightSLine />
                      </div>
                    </Link>
                  </div>
                  <div className="row">
                    <div className="col-8 col-lg-8 col-md-10  text-end">
                      <Link to={`/${next.slug}/`}>
                        <h5
                          className="prev-heading"
                          dangerouslySetInnerHTML={{ __html: next.Title }}
                        ></h5>
                      </Link>
                      <div className="nextAdminUser d-flex justify-content-end align-items-center">
                        <div className="august">
                          <span>{next.date}</span>
                        </div>
                        {/* <div className="adminImg">
                          <GatsbyImage
                            image={
                              next?.adminImg.localFile?.childImageSharp
                                ?.gatsbyImageData
                            }
                            alt="costpixel"
                            loading="eager"
                            objectFit="cover"
                          />
                        </div> */}
                        {/* <div className="adminBy">
                          <span>by</span>
                          <span>
                            <Link
                              to={`/${next.admin_users[0].username?.replace(
                                " ",
                                "-"
                              )}/`}
                            >
                              {next.admin_users[0].username}
                            </Link>
                          </span>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-4 col-md-2 d-flex justify-content-end">
                      <Link to={`/${next.slug}/`}>
                        <div className="slide-image">
                          <GatsbyImage
                            image={
                              next.image.localFile?.childImageSharp
                                ?.gatsbyImageData
                            }
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                            alt="reviews-content"
                            loading="eager"
                            className="img-fluid"
                            objectFit="none"
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      );
    } else if (obj?.next == null && obj?.previous) {
      const previous = obj?.previous;

      return (
        <div className="col-lg-6 prev-border">
          <div className="prevBox">
            <div className="d-flex mb-4">
              <Link to={`/${previous.slug}/`}>
                <div className="arr-left">
                  <RiArrowLeftSLine />
                </div>
              </Link>
              <span>PREVIOUS ARTICLE</span>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-2 col-4">
                <div className="slide-image">
                  <GatsbyImage
                    image={
                      previous?.image.localFile?.childImageSharp
                        ?.gatsbyImageData
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    alt="reviews-content"
                    loading="eager"
                    className="img-fluid"
                    objectFit="none"
                  />
                </div>
              </div>
              <div className="col-lg-8 col-sm-10 col-8">
                <Link to={`/${previous.slug}/`}>
                  <h5 className="next-heading">{previous?.Title}</h5>
                </Link>
                <div className="prevAdminUser d-flex">
                  <div className="august">
                    <span>{previous.date}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (obj?.previous == null && obj?.next) {
      const next = obj?.next;
      return (
        <div className="col-lg-6 pt-lg-0 pt-4 d-lg-flex  d-xs-flex">
          <div className="nextBox">
            <div className="justify-content-end d-flex align-items-center mb-4">
              <span>NEXT ARTICLE</span>
              <Link to={`/${next?.slug}/`}>
                <div className="arr-right">
                  <RiArrowRightSLine />
                </div>
              </Link>
            </div>
            <div className="row">
              <div className="col-8 text-end">
                <Link to={`/${next?.slug}/`}>
                  <h5 className="prev-heading">{next?.Title}</h5>
                </Link>
                <div className="nextAdminUser d-flex justify-content-end">
                  <div className="august">
                    <span>{next.date}</span>
                  </div>
                </div>
              </div>
              <div className="col-4 d-flex justify-content-end">
                <div className="slide-image">
                  <GatsbyImage
                    image={
                      next?.image.localFile?.childImageSharp?.gatsbyImageData
                    }
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    alt="reviews-content"
                    loading="eager"
                    className="img-fluid"
                    objectFit="none"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default React.memo(NextPreviousArticle);