import React, { useState } from 'react'
import { WhiteLogo } from '../../svg'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { caseError, caseSuccess } from '../Validations/SubscriptionForm';
import './Subscribe.scss'

const Subscribe = () => {
    const [email, setEmail] = useState("");
    const [response, setResponse] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [accessToken, setAccessToken] = useState('SW8DnHlcXfL0qZ3p3A6JVFCGuZHgbQBY')
    const [refreshToken, setRefreshToken] = useState('6A9C0ye3apjd8TqtIdXNiKaPCqtEuLu9')

    function resLoad(setRes, setLoad) {
        setResponse(setRes);
        setIsLoading(setLoad);
    }

    const refreshTokenUrl = `https://auth.aweber.com/oauth2/token?grant_type=refresh_token&refresh_token=${refreshToken}`;

    const refreshAccessToken = async () => {
        try {
            const res = await fetch(refreshTokenUrl, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'Authorization': 'Basic ' + btoa('FawoOSmefXDLBCV6xcxybCfKb2Fiu2wC:TXyuJq1DjoM1sMkkgpEtv84h71twxLXf'),
                },
                method: "POST",
            })
            const data = await res.json()
            setAccessToken(data.access_token);

            //after refresh token adding subscriber
            // ----------------------------------------------------------------------------------------------------

            await fetch("https://api.aweber.com/1.0/accounts/1958895/lists/6347465/subscribers", {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${data.access_token}`
                },
                method: "POST",
                body: JSON.stringify({
                    email: email
                })
            })
                .then(data => {
                    if (data.status == 201) {
                        caseSuccess("responseCTA");
                        resLoad("You have successfully subscribed.", false);
                        setEmail('');
                    }
                    else {
                        //in case the email already used or unacceptable email
                        caseError("responseCTA");
                        resLoad("Use Another Email.", false);
                    }
                })
                .catch(err => {
                    caseError("responseCTA");
                    resLoad("Invalid Email.", false);
                })
            // ----------------------------------------------------------------------------------------------------
        } catch (e) {
            console.log(e)
        }
    }

    const Useremail = async () => {
        setIsLoading(true);
        if (email != "") {
            await fetch("https://api.aweber.com/1.0/accounts/1958895/lists/6347465/subscribers", {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: "POST",
                body: JSON.stringify({
                    email: email
                })
            })
                .then(data => {
                    if (data.status == 201) {
                        caseSuccess("responseCTA");
                        resLoad("You have successfully subscribed.", false);
                        setEmail('');
                    }
                    else if (data.status == 401) {
                        refreshAccessToken();
                    }
                    else {
                        caseError("responseCTA");
                        resLoad("Use Another Email.", false);
                    }
                })
                .catch(err => {
                    caseError("responseCTA");
                    resLoad("Invalid Email.", false);
                })
        } else {
            caseError("responseCTA");
            resLoad("Please enter all valid details.", false);
        }
    };


    return (
        <div className='cta-container'>
            <div className='p-4 inner-cta' style={{ position: 'relative' }}>
                <WhiteLogo />
                <h2>New reasons to get excited every week</h2>
                <p className='text'>Get the most important news, reviews and deals in mobile tech delivered straight to your inbox</p>
                <input className='s-input' value={email}
                    onChange={(e) => setEmail(e.target.value)} placeholder='Enter Your Email' />
                <button onClick={(e) => {
                    e.preventDefault();
                    Useremail();
                }} className='s-subs'>SUBSCRIBE</button>
                <p className="responseCTA">{response}</p>
                {isLoading ? <LoadingSpinner /> : null}
            </div>

        </div>
    )
}

export default Subscribe