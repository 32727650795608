import React, { useContext, useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { AiOutlineTwitter } from "@react-icons/all-files/ai/AiOutlineTwitter";
import { ImPinterest } from "@react-icons/all-files/im/ImPinterest";
import loadable from '@loadable/component'
const FeaturedCategories = loadable(() => import('./FeaturedCategories'))
const ArticleForm = loadable(() => import('./ArticleForm'))
const RelatedPosts = loadable(() => import('./RelatedPosts'))
import ArticlePosts from "./ArticlePosts";
import FacebookTwitterButton from "./FacebookTwitterButton";
import Layout from "../components/Layout/layout";
import "./Articles.scss";
import { graphql, Link, useStaticQuery } from "gatsby";
import Seo from "../components/Seo/seo";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import styled from 'styled-components'
import { GlobalStateContext } from "../components/Context/Context";

const Div = styled.div`
    padding-top: 30px;
    padding-bottom: 70px;
    min-width: 300px;
    max-width: 300px;

    div.inner{
        /* padding-top: 29px; */
        /* margin-bottom: -50px; */

        h2{
          font-family: "Barlow Condensed", sans-serif;
            color: #231f20;
            font-size: 24px;
            line-height: 26px;
            margin: 0 0 25px;
            text-align: center;
            font-weight: bold;
        }

        div{
            color: #767474;
            height: 72px;
            display: flex;
            align-items: center;
            justify-content: center;

            .yes{
                background: none !important;
                color: #02838d;
                font-weight: 600;
                text-align: center;
                font-size: 20px;
                border-radius: 72px;
                border: 1px solid #02838d;
                line-height: 72px;
                width: 72px;
                height: 72px;
                margin: 0 15px;
                text-decoration: none;

                &:hover{
                    background-color: #02838d !important;
                    color: white;
                    border-color: #02838d;
                }
            }

            .no{
                background: none !important;
                color: #f0533a;
                font-weight: 600;
                text-align: center;
                font-size: 20px;
                border-radius: 72px;
                border: 1px solid #f0533a;
                line-height: 72px;
                width: 72px;
                height: 72px;
                margin: 0 15px;
                text-decoration: none;
                &:hover{
                    background-color: #f0533a !important;
                    color: white;
                    border-color: #f0533a;
                }
            }
        }
    }
`
interface ArticleProps {
  pageContext;
}

function decodeHtml(html) {
  if (typeof window !== `undefined`) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
}

const Articles = (props: ArticleProps) => {
  const { pageContext } = props;
  // const { yesModal, setYesModal, noModal, setNoModal } = useContext(GlobalStateContext);

  const data = useStaticQuery(graphql`
    {
      allStrapiPost {
        nodes {
          image {
            localFile {
              childImageSharp {
                fixed {
                  src
                }
                gatsbyImageData(height: 700, width: 1050, layout: CONSTRAINED)
              }
            }
          }
          slug
          blog_tags {
            tagName
            tagLink
            id
          }
          admin_users {
            username
          }
        }
      }
    }
  `);

  const [url, setUrl] = useState('')
  const [src, setSrc] = useState('')

  if (typeof window !== "undefined") {
    const path = window.location.pathname;
    const origin = window.location.origin;
    setUrl(path)
    let obj = data.allStrapiPost.nodes.find((e) => path.includes(e.slug));
    setSrc(origin + obj.image.localFile.childImageSharp.fixed.src)
  }
  return (
    <Layout>
      <Seo title={decodeHtml(pageContext.title)}
        description={pageContext.excerpt}
      />
      <div className="article-page py-5 px-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 block-size">
              <div className="article-content">
                <ul className="d-flex pb-5 text-uppercase">
                  {pageContext.category &&
                    pageContext.category.map((e) => {
                      return (
                        <li key={e.slug}>
                          <Link to={`/category/${e.category.toLowerCase()}/`}>
                            {e.category}</Link>
                        </li>
                      );
                    })}
                </ul>
                <h1
                  className="pt-1 pb-2"
                  dangerouslySetInnerHTML={{ __html: pageContext.title }}
                ></h1>
                <div className="admin-image d-flex align-items-center">
                  <Link to={`/${pageContext.adminLink}/`}>
                    <GatsbyImage
                      image={pageContext?.adminImg?.localFile?.childImageSharp?.gatsbyImageData}
                      className="img-fluid banner-article"
                      loading="eager"
                      alt="admin"
                    />
                  </Link>
                  <Link to={`/${pageContext.adminLink.toLowerCase().replace(" ", "-")}/`} className="admin-content-art d-flex align-items-baseline">
                    <small className="ms-2"> by <span className="fw-bold" > {`${pageContext.adminName}`} </span> </small>
                  </Link>
                  <div className="august">
                    <span>{pageContext.date}</span>
                  </div>
                </div>
              </div>

              {data.allStrapiPost.nodes.map((e, i) => {
                if (e.slug === pageContext.slug) {
                  return (
                    <div className="article-banner-image w-100 pt-4" key={i}>
                      <GatsbyImage
                        image={e.image?.localFile?.childImageSharp?.gatsbyImageData}
                        loading="eager"
                        alt="article-banner"
                      />
                    </div>
                  );
                }
              })}
              <div className="row pt-4">
                <div className="col-lg-1 d-lg-block d-none">
                  <div className="side-icons">
                    <div className="article-icon">
                      <a
                        target="_blank"
                        href={`https://www.facebook.com/sharer.php?u=https://phonera.in${url}`}
                      >
                        <FaFacebook className="icon_color facebook" />
                      </a>
                    </div>
                    <div className="article-icon">
                      <a
                        target="_blank"
                        href={`https://twitter.com/share?&url=https://phonera.in${url}`}
                      >
                        <AiOutlineTwitter className="icon_color twitter" />
                      </a>
                    </div>
                    <div className="article-icon">
                      <a
                        target="_blank"
                        href={`https://pinterest.com/pin/create/bookmarklet/?url=https://phonera.in${url}&media=${src}`}
                      >
                        <ImPinterest className="icon_color pinterest" />
                      </a>
                    </div>
                    <div className="number pt-3">
                      <span>1</span>
                      <p className="pt-1">Shares</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-11">
                  <div className=" px-lg-3 pt-2">
                    <div className="paragraph">
                      <ReactMarkdown remarkPlugins={[gfm]}>
                        {pageContext.description.data.description}
                      </ReactMarkdown>
                    </div>
                    <div>
                      <div className="cs-search__tags">
                        <ul>
                          {pageContext.button.map((a) => {
                            return (
                              <li key={a.id} >
                                <Link to={`/tag/${a.tagLink}/`}>
                                  {a.tagName}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <FacebookTwitterButton pageContext={pageContext} data={data.allStrapiPost} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 px-lg-5 mt-lg-0 mt-md-5 ">
              <div className="h-100">
                <div className="d-flex flex-column flex-sm-row flex-lg-column set-sticky pt-md-5 pt-lg-0 ">
                  <div>
                    <ArticleForm />
                  </div>
                  <div className="pt-lg-3 pt-sm-0 col-lg-12 col-sm-6  scrolling-effect">
                    <ArticlePosts />
                    {/* <Div >
                      <div className="inner">
                        <h2>Was this article helpful?</h2>
                        <div>
                          <button onClick={() => {
                            setYesModal(true)
                            setNoModal(false)
                          }}
                            className="yes">Yes</button>
                          <button onClick={() => {
                            setYesModal(false)
                            setNoModal(true)
                          }} className="no">No</button>
                        </div>
                      </div>
                    </Div> */}
                    <FeaturedCategories />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RelatedPosts />
        </div>
      </div>
      {/* {yesModal && <CardHelpful />}
      {noModal && <CardHelpful />} */}
    </Layout>
  );
};

export default React.memo(Articles);