import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { GrPinterest } from "@react-icons/all-files/gr/GrPinterest";
import FeedBackForm from "./FeedBackForm";
import NextPreviousArticle from "./NextPreviousArticle";
import Subscribe from "../components/Subscribe/Subscribe";
// const data = useStaticQuery(graphql`
//     {
//       allStrapiPost {
//         nodes {
//           slug
//           Title
//           image {
//             localFile {
//               childImageSharp {
//                 fixed {
//                   src
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   `);

const FacebookTwitterButton = ({ pageContext, data }) => {
  const [url, setUrl] = useState('')
  const [src, setSrc] = useState('')

  if (typeof window !== "undefined") {
    const path = window.location.pathname;
    const origin = window.location.origin;
    setUrl(path)
    let obj = data.nodes.find((e) => path.includes(e.slug));
    setSrc(origin + obj.image.localFile.childImageSharp.fixed.src)
  }
console.log({"src":src,"url":data});
console.log("hello",pageContext.title);
  return (
    <>
      <div className="adminUser py-4">
        <div className="d-lg-flex d-flex align-items-center flex-column flex-md-column flex-lg-row justify-content-center justify-content-lg-start">
          <div className="">
            <div className="admin-two-img h-100">
              <Link to={`/${pageContext.adminLink.toLowerCase().replace(" ", "-")}/`}>
                <StaticImage
                  src="../../src/images/admin-two.png"
                  className="img-fluid banner-article"
                  objectFit="cover"
                  style={{ height: "100%" }}
                  alt="admin"
                  loading="eager"
                />
              </Link>
            </div>
          </div>
          <div className="auth-section ps-md-0 ps-lg-4 ps-0 pt-md-0 pt-4 text-lg-start text-md-center  text-center">
            <p className="mb-0 pt-0 pt-md-3">AUTHOR</p>
            <Link to={`/${pageContext.adminLink.toLowerCase().replace(" ", "-")}/`}>
              <h4 className="mb-0 pt-3 text-uppercase">
                {pageContext.adminName}
              </h4>
            </Link>
          </div>
        </div>
        <Subscribe />
      </div>
      <div className="shareSocial  pt-4 pb-3 ps-3 mt-5 mb-5">
        <div className="row gx-2">
          <div className="facebook-col">
            <a
              target="_blank"
              href={`https://www.facebook.com/sharer.php?u=https://phonera.in${url}`}
            >
              <button className=" btnfbshare d-flex align-items-center justify-content-center">
                <FaFacebook />
                <span>SHARE</span>
              </button>
            </a>
          </div>
          <div className="twitter-col">
            <a
              target="_blank"
              // href="https://twitter.com/share?&text=Best%20Android%20Phones%20Under%2040%2C000%20in%20India%20%282021%29%20%E2%80%93%20Phonera&url=https://phonera.in/best-android-phones/"
              href={`https://twitter.com/share?&url=https://phonera.in${url}&text=${pageContext.title}`}
            >
              <button className=" btntwitter d-flex align-items-center justify-content-center">
                <FaTwitter />
                <span>TWEET</span>
              </button>
            </a>
          </div>
          <div className="pinterest-col">
            <a
              target="_blank"
              href={`https://pinterest.com/pin/create/bookmarklet/?url=https://phonera.in${url}&media=${src}`}
            >
              <button className=" btnPinterest d-flex align-items-center justify-content-center w-100">
                <GrPinterest />
              </button>
            </a>
          </div>
        </div>
      </div>
      <FeedBackForm title={pageContext.title} />
      <NextPreviousArticle />
    </>
  );
};

export default React.memo(FacebookTwitterButton);